import tools from "../../../extends/tools";
import apis from "../../common/js/api2";
export default {
    printForm() {
        this.printSimpleForm = true;
    },
    printLodopExit() {
        this.printSimpleForm = false;
    },

    init() {
        //获取数据源
        apis.purchaseTuiSource().then(res => {
            if (tools.msg(res, this)) {
                for (let i in res.data) {
                    if (res.data[i]) {
                        this.$set(this.source, i, res.data[i]);
                    } else {
                        this.$set(this.source, i, []);
                    }
                }
            }
        }).catch(err => {
            tools.err(err, this);
        });
        //如果是编辑，则获取详情
        let id = this.$route.query.id;
        if (id > 0) {
            //查看详情禁止编辑
            if (this.$route.query.read == 1) {
                this.isRead = true;
            }
            apis.purchaseTuiDetail({
                id: id
            }).then(res => {
                if (tools.msg(res, this)) {
                    this.initData(res.data);
                }
            }).catch(err => {
                tools.err(err, this);
            });
        }
    },
    initData(data) {
        let goods = [];
        data.goods_info.forEach((item, key) => {
            for (let i in item) {
                if (i === 'price' || i === 'subtotal') {
                    item[i] = tools.setPrice(item[i]);
                }
            }
            item.sum = item.subtotal;
            item.retail_price = item.price;
            item.id = item.details_id;
            item.name = item.goods_name;
            item.unit = item.goods_unit_name;
            item.specification_code = item.product_code;
            item.pic = item.specifications_pic;
            item.dispose_info = item.specifications.join(',');

            goods.push(item);
        });
        this.goodsList = goods;

        this.saveData = {
            warehouse_id: data.warehouse_id,
            number: data.number,
            supplier_id: data.supplier_id,
            user_id: data.user_id,
            business_date: data.business_date,
            additional_amount: tools.setPrice(data.additional_amount),
            settlement_method: data.settlement_method,
            remarks: data.remarks,
            payment_amount: tools.setPrice(data.payment_amount),
            enclosure: data.enclosure,
            goods: goods,
            status: data.status
        }

        this.showData = data;
        console.log(this.saveData);
        this.changeSum();
    },
    changeSum() {
        this.sumData.sumNum = 0;
        this.sumData.allSumPrice = 0;
        this.sumData.sumPrice = 0;
        this.goodsList.forEach((item, key) => {
            this.sumData.sumNum += item.num;
            console.log((item.retail_price));
            this.sumData.sumPrice += parseFloat(item.retail_price) * item.num;

        });
        this.sumData.allSumPrice = tools.toDecimal2(this.sumData.sumPrice + parseFloat(this.saveData.additional_amount));
        this.sumData.sumPrice = tools.toDecimal2(this.sumData.sumPrice);
    },
    cancel() {
        this.$router.push({
            path: '/purchase'
        });
    },
    exportData() {

    },
    remove() {
        let id = this.$route.query.id;
        this.$confirm('确定要删除吗?', '删除确认', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
        }).then(() => {
            apis.purchaseTuiDelete({ id: id }).then((info) => {
                if (info.code == 200) {
                    this.tableData.splice(this.tableData.findIndex(item => item.id === id), 1);
                    this.$message({
                        type: 'success',
                        message: '删除成功!'
                    });

                    this.$router.push({
                        path: "/purchase"
                    })
                } else {
                    this.$message({
                        type: 'error',
                        message: info.msg
                    });
                }
            })
        }).catch(() => {
            this.$message({
                type: 'info',
                message: '已取消删除'
            });
        });
    },
    copyRecord() {
        let id = this.$route.query.id;
        this.$router.push({
            path: '/purchase_tui/add',
            query: {
                id: id,
                is_copy: 1
            }
        });
    },
    edit() {
        let id = this.$route.query.id;
        this.$router.push({
            path: '/purchase_tui/add',
            query: {
                id: id
            }
        });
    },
    apply(status) {
        let id = this.$route.query.id;

        let loader = tools.loading(false, this);

        apis.purchaseTuiApply({
            id: id,
            status: status
        }).then(res => {
            tools.msg(res, this, 1);
            tools.loading(loader, this);
        }).catch(err => {
            tools.err(err, this);
            tools.loading(loader, this);
        });
    },
}